import { Psp } from '../api-utils/service-requests/checkout'
import { debounce } from './debounce'

//https://github.com/vercel/next.js/discussions/20784
declare const window: Window & { dataLayer: Record<string, unknown>[] }

const sendEvent = (event: string, data: Record<string, unknown>) => {
  if (!window.dataLayer) {
    return
  }

  const timeStamp = Date.now()

  window.dataLayer.push({
    event,
    ...data,
    timeStamp,
  })
}

const SEARCH_DEBOUNCE_MS = 750
const search = (query: string) => {
  sendEvent('search', { search_term: query })
}

const pageView = (url: string, basePath: string) => {
  sendEvent('page_view', { page_path: url, page_location: basePath })
}

const cart = {
  add: function addToCart(name: string, id: string, quantity: number) {
    sendEvent('add_to_cart', {
      name,
      id,
      quantity,
    })
  },
  remove: function (name: string, id: string) {
    sendEvent('remove_from_cart', {
      name,
      id,
    })
  },
}

type Step =
  | 'view_tab'
  | 'select_item_on_tab'
  | 'deselect_item_on_tab'
  | 'view_cart'
  | 'view_payment_methods'
  | 'change_payment_method'
  | 'begin_payment'
  | 'view_confirming_payment'
  | 'complete_purchase'
  | 'cancel_purchase'
  | 'failed_purchase'
  | 'view_receipt'
  | 'change_tip'

const checkoutEvent =
  (step: Step) =>
  (metaData: Record<string, unknown> = {}) => {
    sendEvent(step, {
      ...metaData,
    })
  }

const checkout = {
  viewTab: checkoutEvent('view_tab'),
  selectItemOnTab: checkoutEvent('select_item_on_tab'),
  deselectItemOnTab: checkoutEvent('deselect_item_on_tab'),
  viewOrder: checkoutEvent('view_cart'),
  changeTip: (tip: number) => checkoutEvent('change_tip')({ tip }),
  viewPaymentMethods: (checkoutId: string) => checkoutEvent('view_payment_methods')({ checkoutId }),
  changePaymentMethod: (paymentProvider: Psp) =>
    checkoutEvent('change_payment_method')({ paymentProvider }),
  beginPayment: checkoutEvent('begin_payment'),
  viewConfirmOrder: checkoutEvent('view_confirming_payment'),
  cancelPurchase: checkoutEvent('cancel_purchase'),
  completePurchase: checkoutEvent('complete_purchase'),
  failedPurchase: checkoutEvent('failed_purchase'),
  viewReceipt: checkoutEvent('view_receipt'),
}

type BaseSplitItem = {
  id: string
  title: string
  price: number
  basePrice: number
  currency: string
}

type SplitItemMetaData = {
  baseItem: BaseSplitItem
  numOfParts: number
  numOfTabMembers: number
}

const splitItem = {
  open: function (id: string) {
    sendEvent('open_split_item_ui', {
      id,
    })
  },
  close: function (id: string) {
    sendEvent('close_split_item_ui', {
      id,
    })
  },
  changeNumOfPartsInput: function (metaData: { id: string; numOfParts: number; type: string }) {
    sendEvent('change_split_item_parts_input', {
      ...metaData,
    })
  },
  createSplits: function (metaData: SplitItemMetaData) {
    sendEvent('create_split_items', {
      ...metaData,
    })
  },
  changeSplits: function (metaData: SplitItemMetaData) {
    sendEvent('change_split_items', {
      ...metaData,
    })
  },
}

const translation = {
  openLanguageSelector: function () {
    sendEvent('open-translation-selector', {})
  },
  closeLanguageSelector: function () {
    sendEvent('close-translation-selector', {})
  },
  selectLanguage: function (metaData: { lang: string | null }) {
    sendEvent('select-translation-language', { ...metaData })
  },
  clearTranslation: function (metaData: { lang: string }) {
    sendEvent('clear-translation-language', { ...metaData })
  },
}

const filter = {
  open: function () {
    sendEvent('open_filter_ui', {})
  },
  apply: function (metaData: { filters: string }) {
    sendEvent('apply_filter', { ...metaData })
  },
}

const analytics = {
  events: {
    // Google default events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events
    search: debounce(search, SEARCH_DEBOUNCE_MS),
    filter,
    cart,
    checkout,
    splitItem,
    translation,
  },
  pageView,
}

export default analytics
