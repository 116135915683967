import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Text } from '../../ui/Typography'
import { Locale, makeLocale } from '../helpers'
import { HorizontalKarmaLogo } from '../index'

export const Footer = () => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]

  return (
    <footer className="container py-6 justify-center sm:flex sm:items-center">
      <Link
        href={'https://karma.life?utm_source=storefront&utm_medium=web&utm_campaign=powered-by'}
        className="sm:mt-0 sm:order-1 flex gap-x-2 items-center justify-center">
        <Text level={3}>{translation.poweredBy}</Text>
        <HorizontalKarmaLogo className="h-5 w-auto" color="#000" />
      </Link>
    </footer>
  )
}

type Path = {
  label: string
}
export const translations: {
  [key in Locale]: {
    screenReaderOpenMainMenu: string
    screenReaderOpenCart: string
    poweredBy: string
    paths: {
      about: Path
    }
  }
} = {
  sv: {
    screenReaderOpenMainMenu: 'Öppna menyn',
    screenReaderOpenCart: 'Öppna varukorg',
    poweredBy: 'Drivs av',
    paths: {
      about: {
        label: 'Om oss',
      },
    },
  },
  en: {
    screenReaderOpenMainMenu: 'Open main menu',
    screenReaderOpenCart: 'Open cart',
    poweredBy: 'Powered by',
    paths: {
      about: {
        label: 'About',
      },
    },
  },
  no: {
    screenReaderOpenMainMenu: 'Åpne menyen',
    screenReaderOpenCart: 'Åpen handlekurv',
    poweredBy: 'Drevet av',
    paths: {
      about: {
        label: 'Om oss',
      },
    },
  },
}
