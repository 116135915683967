import * as t from 'io-ts'

export const LanguageCode = t.union([
  t.literal('en'),
  t.literal('sv'),
  t.literal('no'),
  t.literal('fi'),
  t.literal('es'),
  t.literal('de'),
  t.literal('fr'),
  t.literal('ar'),
  t.literal('pl'),
  t.literal('ru'),
  t.literal('zh'),
  t.literal('ja'),
])

export type LanguageCode = t.TypeOf<typeof LanguageCode>

export enum LocalesWithRegions {
  sv = 'sv-SE',
  en = 'en-US',
  no = 'no-NO',
}

export const TranslationLanguage = t.type({
  langCode: LanguageCode,
  name: t.string,
})

export type TranslationLanguage = t.TypeOf<typeof TranslationLanguage>
