import { useEffect } from 'react'

export function useLockBodyScroll(shouldPreventBodyScroll: boolean) {
  useEffect(() => {
    if (shouldPreventBodyScroll) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [shouldPreventBodyScroll])
}
