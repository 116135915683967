import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import analytics from '../../utils/analytics'
import { LanguageSelector } from '../language-selector'

export const LanguageSelectorButton = () => {
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState<boolean>(false)

  return (
    <>
      <button
        onClick={() => {
          analytics.events.translation.openLanguageSelector()
          setIsLanguageSelectorOpen(true)
        }}
        className="bg-default py-3 px-4 absolute right-[-0.9rem] top-[-0.55rem]">
        <FontAwesomeIcon size="xl" icon={faGlobe} />
      </button>
      <LanguageSelector
        isLanguageSelectorOpen={isLanguageSelectorOpen}
        setIsLanguageSelectorOpen={setIsLanguageSelectorOpen}
      />
    </>
  )
}
