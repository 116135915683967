import Timeout = NodeJS.Timeout

export const debounce = function (
  fn: Function,
  delay: number,
  options: { leading?: boolean; trailing?: boolean } = {}
) {
  let timeoutId: Timeout | null = null
  let lastCallTime: number | null = null
  let lastResult: Function

  function debounced(this: unknown, ...args: unknown[]) {
    const now = Date.now()
    const isLeading = options.leading && !lastCallTime
    const isTrailing = options.trailing && lastCallTime

    if (isLeading) {
      lastCallTime = now
      lastResult = fn.apply(this, args)
    }

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    if (isTrailing) {
      timeoutId = setTimeout(() => {
        lastCallTime = null
        lastResult = fn.apply(this, args)
      }, delay)
    }

    if (!isLeading && !isTrailing) {
      timeoutId = setTimeout(() => {
        lastCallTime = null
        lastResult = fn.apply(this, args)
      }, delay)
    }

    return lastResult
  }

  debounced.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  return debounced
}
