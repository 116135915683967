import React, { FC } from 'react'

import { useSession } from '../../hooks/useSession'
import { LocationInformation } from '../../models/types'
import { getSelectedTheme } from '../../utils/location-center-helpers'
import BrandTheme from '../brand-theme'
import { Footer } from '../footer'
import { Head } from '../head'
import { Navbar, NavbarOptions } from '../navbar'

type SessionfulLayout = {
  title?: string
  information: Pick<LocationInformation, 'themes' | 'locationCenters'>
  description?: string
  navBarOptions?: NavbarOptions
  hideNavbar?: boolean
  hideFooter?: boolean
  bannerText?: string
  classNames?: string
  qrCodeId?: string
}

export const Layout: FC<SessionfulLayout> = ({
  title,
  description,
  navBarOptions,
  hideNavbar,
  hideFooter = true,
  children,
  bannerText,
  information: themeInfo,
  classNames,
  qrCodeId,
}) => {
  const { session } = useSession()
  const selectedTheme = getSelectedTheme(themeInfo, session?.qrCodeParams ?? {})
  return (
    <>
      <Head title={title} description={description} qrCodeId={qrCodeId} />
      <BrandTheme selectedTheme={selectedTheme} />
      <div className={'h-full md:container md:mx-auto md:px-24 lg:px-48 xl:px-80 ' + classNames}>
        <div className="flex flex-col flex-1">
          {bannerText && (
            <div
              className="w-full justify-center flex align-center p-2 z-layout"
              style={{ backgroundColor: '#FF80B4' }}>
              <p className="font-semibold">{bannerText}</p>
            </div>
          )}
          {!hideNavbar && (
            <div className="sticky top-0 inset-x-0 z-navbar">
              <Navbar locationInfo={themeInfo} options={navBarOptions} />
            </div>
          )}
          <main className="grow">{children}</main>
          {!hideFooter && <Footer />}
        </div>
      </div>
    </>
  )
}
